import { handleResponse, handleError, headerset } from "../apiUtils";
import { RegimeCommerciale } from "../../components/anagrafiche/regimiCommerciali/regimiCommercialiSlice";

const baseUrl = (regime_commerciale_id?: number | null) => {
	if (regime_commerciale_id) {
		return (
			process.env.API_URL +
			"/api/anagrafiche/regimi_commerciali/" +
			regime_commerciale_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/anagrafiche/regimi_commerciali/";
	}
};

export function fetchRegimiCommerciali(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();

	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getRegimeCommerciale(regimeCommercialeId: number) {
	let url = baseUrl(regimeCommercialeId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveRegimeCommerciale(
	regimeCommercialeToSave: RegimeCommerciale
) {
	const regimeCommerciale = {
		...regimeCommercialeToSave,
	};

	return fetch(baseUrl(regimeCommercialeToSave.id), {
		method: regimeCommercialeToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...regimeCommerciale,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteRegimeCommerciale(
	regimeCommercialeToDelete: RegimeCommerciale
) {
	return fetch(baseUrl(regimeCommercialeToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
