import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Routes_path } from "../../../../routerApp";
import {
	Clienti,
	Cliente,
	fetchClienti,
} from "../../../../anagrafiche/clienti/clientiSlice";
import { EsponiStatoSintetico } from "components/common/EsponiStatoSintetico";

import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import { ErrorStatusTypes } from "../../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
} from "../../../../anagrafiche/kpi/kpiSlice";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { IterConnessione } from "../../../iterConnessioni/iterConnessioneSlice";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoServizio } from "components/anagrafiche/tipiServizio/tipiServizioSlice";
import { Commessa } from "../../commesseSlice";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import IterConnessioneDocumentiTab from "../../../../main/iterConnessioni/iterConnessione/documenti/IterConnessioneDocumentiTab";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import { theme } from "../../../../theme";

interface IterConnessioniCommessaGridProps {
	commessa: Commessa;
}

const IterConnessioniCommessaGrid = ({
	commessa,
}: IterConnessioniCommessaGridProps) => {
	
	const dispatch = useAppDispatch();

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const clienti: Clienti = useSelector<RootState, Clienti>(
		(state) => state.clientiState.clienti
	);

	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const iter_connessioni = useSelector<RootState, IterConnessione[]>(
		(state) => state.iterConnessioniState.iterConnessioni.results
	);

	const iterConnessione = iter_connessioni.find(
		(iterConnessione) => iterConnessione.id == commessa.iterConnessione
	);

	const [iterConnessioneScelta_id, setIterConnessioneScelta_id] = useState<
		number | undefined
	>(undefined);
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		dispatch(fetchClienti({}));
	}, []);

	return (
		<>
			{iterConnessione && (
				<Paper elevation={3} sx={{ p: 3 }}>
					<Typography gutterBottom variant="h5">
						Iter Connessione
					</Typography>
					<Grid container spacing={0.5}>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Iter
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Pod
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={1}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Stato
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Data ultimo aggiornamento
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Tipo servizio
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={1}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Docum.
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						spacing={0.5}
						// key={index}
						// sx={{ backgroundColor: index % 2 === 0 ? "#eeeeee" : "inherit" }}
					>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<IconButton
								key={iterConnessione.commessa}
								aria-label="vai a commessa"
								size="small"
								component={Link}
								target="_blank"
								to={{
									pathname: Routes_path.ITER_CONNESSIONE + iterConnessione.id,
								}}
								// state={{
								// 	lavorazine_id: params.value,
								// }}
							>
								<Typography
									variant="body1"

									// color="text.secondary"
									// onClick={() => setEditing(true)}
								>
									{iterConnessione.codice_pratica}
								</Typography>
							</IconButton>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="body1"
								// sx={{ fontSize: "0.8rem" }}
								// color="text.secondary"
								// onClick={() => setEditing(true)}
							>
								{iterConnessione.pod_commessa?.codice}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={1}>
							<Typography
								variant="body1"
								// color="text.secondary"
								// onClick={() => setEditing(true)}
							>
								{EsponiStatoSintetico(iterConnessione.stato)}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="body1"
								// color="text.secondary"
								// onClick={() => setEditing(true)}
							>
								{iterConnessione.data_ultimo_stato
									? convertToDateTime(iterConnessione.data_ultimo_stato)?.setLocale("it").toFormat("dd/MM/yyyy") : ""}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="body1"
								// color="text.secondary"
								// onClick={() => setEditing(true)}
							>
								{
									tipiServizio.find(
										(tipoServizio) =>
											tipoServizio.id == iterConnessione.tipo_servizio
									)?.codice
								}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={1}>
							{utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							]) && (
								<IconButton
									aria-label="stampa"
									size="small"
									onClick={() => {
										iterConnessione.id &&
											setIterConnessioneScelta_id(iterConnessione.id);
									}}
								>
									<FolderOpenIcon fontSize="inherit" />
								</IconButton>
							)}
						</Grid>
					</Grid>

					{iterConnessioneScelta_id && (
						<IterConnessioneDocumentiTab
							key={`iterConnessioneScelta_id_${iterConnessioneScelta_id}`}
							iterConnessione_id={iterConnessioneScelta_id}
							utente={utente}
							consentiModifica={utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
							])}
						/>
					)}
				</Paper>
			)}
		</>
	);
};

export default IterConnessioniCommessaGrid;
