import React, {useEffect} from "react";
import {useAppDispatch} from "../../../redux/store";
import {useForm, SubmitHandler} from "react-hook-form";
import {TipoTensione, saveTipoTensione, deleteTipoTensione} from "./tipiTensioneSlice";

import {
    Box,
    TextField,
    Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";
import tipoTensioneNuovoForm from "./TipoTensioneNuovoForm";

interface TipoTensioneFormProps {
    tipoTensione: TipoTensione;
    consentiModifica: boolean;
}

const TipoTensioneForm = ({tipoTensione, consentiModifica}: TipoTensioneFormProps) => {
    const {handleSubmit, register, formState: {errors, isDirty, isValid}, getValues, reset} = useForm<TipoTensione>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        reset(tipoTensione);
    }, [tipoTensione, reset]);

    const handlerSaveTipoTensione = (tipoTensione: TipoTensione) => {
        consentiModifica && dispatch(saveTipoTensione(tipoTensione));
    };

    const onSubmit: SubmitHandler<TipoTensione> = (tipoTensione) => {
        handlerSaveTipoTensione(tipoTensione);
    };

    const handlerDetailChangeSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveTipoTensione(formValues);
        }
    };

    // gestione chiusura modal su scelta "Annulla"
    const [openConferma, setOpenConferma] = React.useState(false);
    const handleConfermaClose = () => setOpenConferma(false);
    const handleModalopenConfermaOpen = () => {
        setOpenConferma(true);
    };

    // gestione cancellazione tipo tensione
    const handlerDeleteTipoTensione = (tipoTensioneToDelete: TipoTensione) => {
        if (tipoTensioneToDelete != undefined) {
            dispatch(deleteTipoTensione(tipoTensioneToDelete));
            handleConfermaClose();
        }
    };

   return (
        <Box sx={{py:1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {tipoTensione?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {tipoTensione.errorsStack.fieldsErrors.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid size={{xs:12, sm:6}}>
                        <TextField
                            // label="Nome"
                            variant="outlined"
                            size="small"
                            {...register("nome", {required: "Campo obbligatorio"})}
                            onBlur={handlerDetailChangeSubmit}
                            error={!!errors?.nome}
                            helperText={errors?.nome?.message}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    <Grid size={{xs:12, sm:6}}>
                        <TextField
                            // label="Descrizione"
                            variant="outlined"
                            size="small"
                            {...register("descrizione")}
                            onBlur={handlerDetailChangeSubmit}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    {/* --- cancellazione commentata perchè è necessaria una progettazione attenta del foreign key ---*/}
                    {/*<Grid size={{xs:12, sm:1}}>*/}
                    {/*    <IconButton*/}
                    {/*        // label="Codice Distributore"*/}
                    {/*        onClick={() => handleModalopenConfermaOpen()}*/}
                    {/*    >*/}
                    {/*        <DeleteForeverIcon fontSize="inherit"/>*/}
                    {/*    </IconButton>*/}
                    {/*</Grid>*/}
                </Grid>
            </form>
            <ConfermaAnnulla
                handleAnnulla={handleConfermaClose}
                handleConferma={() => handlerDeleteTipoTensione(tipoTensione)}
                domanda={
                    "Sicuro di voler cancellare il tipo tensione " + tipoTensione.nome

                }
                open={openConferma}
            />
        </Box>
    );
};

export default TipoTensioneForm;
