import {fetchUtentiApplicativo, fetchUtentiStaff} from "./authentication/authenticationSlice";
import { fetchRegimiCommerciali } from "./anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { fetchTipiPratica } from "./anagrafiche/tipiPratica/tipiPraticaSlice";
import { fetchTipiServizio } from "./anagrafiche/tipiServizio/tipiServizioSlice";
import { fetchTipiTensione } from "./anagrafiche/tipiTensione/tipiTensioneSlice";
import { fetchClienti } from "./anagrafiche/clienti/clientiSlice";
import { fetchInstallatori } from "./anagrafiche/installatori/installatoriSlice";
import { fetchGestori } from "./anagrafiche/gestori/gestoriSlice";
import { fetchComuni } from "./anagrafiche/comuni/comuniSlice";
import { fetchTipoIntervento } from "./main/commesse/commesseSlice";
// import { fetchProduttori } from "./anagrafiche/produttori/produttoriSlice";

import {
	utenteTokenRefresh,
	UtenteLogged,
} from "./authentication/authenticationSlice";

import {
	fetchStatiIter,
	fetchStatiConnessioni,
	fetchConteggioStatiSopralluoghi,
	fetchConteggioStatiProgetti,
	fetchConteggioStatiIter,
	fetchConteggioDispacciamentoGse,
	fetchConteggioPraticheEnea,
	fetchConteggioPraticheOfficinaElettrica,
	fetchConteggioPraticheAutorizzazioniComunali,
} from "./anagrafiche/kpi/kpiSlice";

export const fetchAnagrafiche = (
	dispatch: any,
	utente: UtenteLogged,
	ritardoScaglionamento: number, // Ritarda ogni dispatch di 1000 == secondo
	comuniUltimoAggiornamento?: string
) => {
	const actions = [
		() => dispatch(fetchUtentiStaff({})),
		() => dispatch(fetchUtentiApplicativo({})),
		() => dispatch(fetchStatiConnessioni()),
		() => dispatch(fetchTipoIntervento({})),
		() => dispatch(fetchTipiPratica({})),
		() => dispatch(fetchStatiIter()),
		() => dispatch(fetchTipiServizio()),
		() => dispatch(fetchRegimiCommerciali({})),
		() => dispatch(fetchClienti({})),
		() => dispatch(fetchInstallatori({})),
		() => dispatch(fetchGestori({})),
		() => dispatch(fetchTipiTensione({})),
		() => dispatch(fetchComuni({ ultimoAggiornamento: comuniUltimoAggiornamento })),
		() => dispatch(fetchConteggioStatiIter()),
		() => dispatch(fetchConteggioStatiSopralluoghi()),
		() => dispatch(fetchConteggioStatiProgetti()),
		() => dispatch(fetchConteggioDispacciamentoGse()),
		() => dispatch(fetchConteggioPraticheEnea()),
		() => dispatch(fetchConteggioPraticheOfficinaElettrica()),
		() => dispatch(fetchConteggioPraticheAutorizzazioniComunali()),
	];

	if (utente.isLogged) {
		actions.forEach((action, index) => {
			setTimeout(action, index * ritardoScaglionamento); // Ritarda ogni dispatch di 1 secondo
		});
	}
};
