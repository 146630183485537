import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import { Link } from "react-router-dom";

import { DateTime } from "luxon";


import { ErrorStatusTypes } from "../../common/errorsDeclarations";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import UtenteForm from "../../authentication/utenti/utente/UtenteForm";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { Cliente, getCliente } from "./clientiSlice";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClienteForm from "./ClienteForm";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../theme";

const ClientePage = () => {
	
	const dispatch = useAppDispatch();
	const { cliente_id } = useParams();
	// const produttoreId: number = parseInt(produttore_id || "");

	const [clienteId, setClienteId] = useState<number>(
		parseInt(cliente_id || "")
	);

	const location = useLocation();
	const navigate = useNavigate();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const clienteCorrenteId = useSelector<RootState, number | undefined>(
		(state) => state.clientiState.clienteCorrenteId
	);

	useEffect(() => {
		clienteId && dispatch(getCliente(clienteId));
	}, []);

	useEffect(() => {
		clienteCorrenteId && setClienteId(clienteCorrenteId);

		clienteCorrenteId &&
			navigate(`${Routes_path.CLIENTE}${clienteCorrenteId}`, {
				replace: true,
			});
	}, [clienteCorrenteId]);

	const cliente: Cliente | undefined = useSelector<
		RootState,
		Cliente | undefined
	>((state) =>
		state.clientiState.clienti.results.find(
			(cliente) => cliente?.id == clienteId
		)
	);

	const clienteNuovo: Cliente = {
		id: undefined,
		general_contractor_abilitato_commesse: false,
		ragione_sociale: "",
	};
	return (
		<>
			{/* <ProduttoreBreadcrumbs produttore={produttore} /> */}
			<Box
				sx={{
					backgroundColor: "#fff",
					elevation: 20,
					p: 2,
				}}
			>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						false
						// lotto.errorsStack.status == ErrorStatusTypes.PENDING ||
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={4}
					sx={{ mb: 1 }}
				>
					{cliente ? (
						<>
							<Typography variant="h3" component="div" color="text.secondary">
								{cliente?.id}
							</Typography>
							<Typography variant="h3" component="div" color="text.secondary">
								{cliente?.ragione_sociale}
							</Typography>
						</>
					) : (
						<Typography variant="h3" component="div" color="text.secondary">
							Nuovo Cliente
						</Typography>
					)}
				</Stack>

				<Divider sx={{ mb: 1 }} />
				<Box>
					<ClienteForm
						key={`ClienteForm_${cliente?.id || "nuovo"}`}
						cliente={cliente || clienteNuovo}
					/>
				</Box>

				<Card
					elevation={2}
					sx={{
						mx: 6,
						mt: 2,
						// minHeight: "80%",
						backgroundColor: "#ddd",
					}}
				>
					<CardHeader
						sx={{ backgroundColor: "#a6ed5e" }}
						title="Utenti"
						action={
							<Button
								component={Link}
								variant="contained"
								to={{
									pathname: Routes_path.UTENTE,
								}}
								state={{
									utente_id: null,
									clientePreset: cliente?.id,
								}}
							>
								crea Utente
							</Button>
						}
					/>
					<CardContent>
						<Grid container spacing={2}>
							{cliente?.utenti?.map((utente) => (
								<Grid size={{xs:6}} key={utente.id}>
									<Card elevation={2} sx={{ p: 2 }}>
										<UtenteForm
											key={utente.id || 0}
											utente={utente}
											clienti={[cliente]}
											comunita_energetiche={[]}
											clientePreset={cliente.id}
										/>
									</Card>
								</Grid>
							))}
						</Grid>
					</CardContent>
				</Card>
			</Box>
		</>
	);
};

export default ClientePage;
