import React, { useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";

import { Gestore } from "./gestoriSlice";

import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

import { Box } from "@mui/system";
import { Button } from "@mui/material";

import Stack from "@mui/material/Stack";
import { theme } from "../../theme";

interface GestoreNuovoForm {
	gestoreNuovo: Gestore;
	saveGestore: (gestore: Gestore) => void;
	annulla: () => void;
}

const GestoreNuovoForm = ({
	gestoreNuovo,
	// regimiCommerciali,
	saveGestore,
	annulla,
}: GestoreNuovoForm) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Gestore>();

	const listaCampi = Object.keys(gestoreNuovo) as Array<keyof Gestore>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, gestoreNuovo[field]);
		});
	}, []);

	const onSubmit: SubmitHandler<Gestore> = (gestore) => {
		saveGestore(gestore);
	};

	useEffect(() => {
		listaCampi.forEach((field) => {
			gestoreNuovo.errorsStack?.fieldsErrors &&
				gestoreNuovo.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: gestoreNuovo.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [gestoreNuovo?.errorsStack?.fieldsErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					// rowSpacing={1}
					columnSpacing={1}
					// justifyContent="space-between"
					// alignItems="flex-start"
				>
					<Grid size={{xs:12, md:4}}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<TextField
							id="ragione_sociale"
							label="Ragione Sociale"
							// disabled={getValues("confermato")}
							{...register("ragione_sociale")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.ragione_sociale}
							helperText={errors?.ragione_sociale?.message}
						/>
					</Grid>
					<Grid size={{xs:12, md:4}}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<TextField
							id="codice_distributore"
							label="Codice Distributore"
							// disabled={getValues("confermato")}
							{...register("codice_distributore")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.codice_distributore}
							helperText={errors?.codice_distributore?.message}
						/>
					</Grid>
					<Grid size={{xs:12, md:4}}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<Button disabled={!isDirty} variant="contained" type="submit">
								Salva
							</Button>
							<Button variant="outlined" onClick={annulla}>
								Annulla
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default GestoreNuovoForm;
