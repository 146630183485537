import React, {useEffect} from "react";
import {useAppDispatch} from "../../../redux/store";
import {useForm, SubmitHandler} from "react-hook-form";
import {TipoPratica, saveTipoPratica, deleteTipoPratica} from "./tipiPraticaSlice";

import {
    Box,
    TextField,
    Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";
import tipoPraticaNuovoForm from "./TipoPraticaNuovoForm";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface TipoPraticaFormProps {
    tipoPratica: TipoPratica;
    consentiModifica: boolean;
}

const TipoPraticaForm = ({tipoPratica, consentiModifica}: TipoPraticaFormProps) => {
    const {handleSubmit, register, formState: {errors, isDirty, isValid}, watch, setValue, getValues, reset} = useForm<TipoPratica>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        reset(tipoPratica);
    }, [tipoPratica, reset]);

    const handlerSaveTipoPratica = (tipoPratica: TipoPratica) => {
        consentiModifica && dispatch(saveTipoPratica(tipoPratica));
    };

    const onSubmit: SubmitHandler<TipoPratica> = (tipoPratica) => {
        handlerSaveTipoPratica(tipoPratica);
    };

    const handlerDetailChangeSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveTipoPratica(formValues);
        }
    };

    // gestione chiusura modal su scelta "Annulla"
    const [openConferma, setOpenConferma] = React.useState(false);
    const handleConfermaClose = () => setOpenConferma(false);
    const handleModalopenConfermaOpen = () => {
        setOpenConferma(true);
    };

    // gestione cancellazione tipo pratica
    const handlerDeleteTipoPratica = (tipoPraticaToDelete: TipoPratica) => {
        if (tipoPraticaToDelete != undefined) {
            dispatch(deleteTipoPratica(tipoPraticaToDelete));
            handleConfermaClose();
        }
    };

   return (
        <Box sx={{py: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {tipoPratica?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {tipoPratica.errorsStack.fieldsErrors.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid size={{xs:12, sm:5}}>
                        <TextField
                            // label="Nome"
                            variant="outlined"
                            size="small"
                            {...register("nome", {required: "Campo obbligatorio"})}
                            onBlur={handlerDetailChangeSubmit}
                            error={!!errors?.nome}
                            helperText={errors?.nome?.message}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    <Grid size={{xs:12, sm:5}}>
                        <TextField
                            // label="Descrizione"
                            variant="outlined"
                            size="small"
                            {...register("descrizione")}
                            onBlur={handlerDetailChangeSubmit}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    <Grid size={{xs:12, sm:2}}>
                        <FormControlLabel
                            value={
                                watch("compatibile_iter_semplificato") ||
                                tipoPratica?.compatibile_iter_semplificato
                            }
                            checked={watch("compatibile_iter_semplificato") || false}
                            control={<Checkbox />}
                            label="Iter Semplificato"
                            labelPlacement="top"
                            onChange={() => {
                                const formValues = getValues();
                                handlerSaveTipoPratica({
                                    ...formValues,
                                    compatibile_iter_semplificato: !formValues.compatibile_iter_semplificato,
                                });
                            }}
                            disabled={!consentiModifica}

                            style={{ padding: 0 }}
                        />
                    </Grid>
                    {/* --- cancellazione commentata perchè è necessaria una progettazione attenta del foreign key ---*/}
                    {/*<Grid size={{xs:12, sm:1}}>*/}
                    {/*    <IconButton*/}
                    {/*        // label="Codice Distributore"*/}
                    {/*        onClick={() => handleModalopenConfermaOpen()}*/}
                    {/*    >*/}
                    {/*        <DeleteForeverIcon fontSize="inherit"/>*/}
                    {/*    </IconButton>*/}
                    {/*</Grid>*/}
                </Grid>
            </form>
            <ConfermaAnnulla
                handleAnnulla={handleConfermaClose}
                handleConferma={() => handlerDeleteTipoPratica(tipoPratica)}
                domanda={
                    "Sicuro di voler cancellare il tipo tensione " + tipoPratica.nome

                }
                open={openConferma}
            />
        </Box>
    );
};

export default TipoPraticaForm;
