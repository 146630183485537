import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";
import IterConnessioneAvvioIterTab from "./avvioIter/IterConnessioneAvvioIterTab";

import {PermissionTypes} from "../../../authentication/permissionsGroups";
import {utenteAutorizzato} from "../../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../../authentication/authenticationSlice";
import {IterConnessione, getIterConnessione} from "../iterConnessioneSlice";
import {
    StatoConnessioneTypes,
    ServizioTypes,
} from "../../../anagrafiche/kpi/kpiSlice";
import {TipoServizio} from "../../../anagrafiche/tipiServizio/tipiServizioSlice";

import {Cliente} from "../../../anagrafiche/clienti/clientiSlice";
import {Soggetto} from "../../../anagrafiche/produttori/produttoriSlice";
import {
    getProgettoFromIter,
} from "../iterConnessione/progetti/progettiSlice";

import IterConnessioneAlerChip from "./IterConnessioneAlerChip";
import IterConnessioneIterImpostazioneTab from "./impostazione/IterConnessioneIterImpostazioneTab";
import IterConnessioneDatiProduttoreTab from "./datiProduttore/IterConnessioneDatiProduttoreTab";
import IterConnessioneDatiPodTab from "./datiPod/IterConnessioneDatiPodTab";
import IterConnessioneDatiPraticaTab from "./datiPratica/IterConnessioneDatiPraticaTab";
import IterConnessioneProgettiTab from "./progetti/IterConnessioneProgettiTab";
import IterConnessioneRichiesteConnessioneTab from "./richiesteConnessione/IterConnessioneRichiesteConnessioneTab";
import IterConnessioneAutorizzazioneComunaleTab
    from "./autorizzazioneComunale/IterConnessioneAutorizzazioneComunaleTab";
import IterConnessioneDocumentiTab from "./documenti/IterConnessioneDocumentiTab";
import IterConnessioneOfficinaElettricaTab from "./officinaElettrica/IterConnessioneOfficinaElettricaTab";
import IterConnessioneEneaTab from "./enea/IterConnessioneEneaTab";
import IterConnessioneConsegnaTab from "./consegna/IterConnessioneConsegnaTab";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

import ArrowStepper from "../../../common/ArrowStepper";
import {
    testoBottoneProseguimento,
    passiInizioPratica,
} from "../../../anagrafiche/produttori/testoBottoneProseguimento";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {getProduttore} from "../../../anagrafiche/produttori/produttoriSlice";
import {Grid, Paper} from "@mui/material";

import {EsponiStatoSintetico} from "components/common/EsponiStatoSintetico";
import {theme} from "../../../theme";
import {electron} from "webpack";

const IterConnessionePage = () => {

    const dispatch = useAppDispatch();
    const {iter_id} = useParams();

    const [iterId, setIterId] = useState<number>(parseInt(iter_id || ""));

    const location = useLocation();
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    const iterConnessioniErrorsStack = useSelector<RootState, ErrorsStack>(
        (state) => state.iterConnessioniState.iterConnessioni.errorsStack
    );

    const tipiServizio = useSelector<RootState, TipoServizio[]>(
        (state) => state.tipiServizioState.tipiServizio.results
    );

    const servizioEscludeEnea = () => {
        const codiceServizio = tipiServizio.find(
            (ts) => ts.id == iterConnessione?.tipo_servizio
        )?.codice;

        const servizioEscludeEnea =
            codiceServizio == ServizioTypes.ITERO_FV ||
            codiceServizio == ServizioTypes.ITERS_FV;
        return servizioEscludeEnea;
    };

    const servizioEscludeOfficinaElettrica = () => {
        const codiceServizio = tipiServizio.find(
            (ts) => ts.id == iterConnessione?.tipo_servizio
        )?.codice;

        const servizioEscludeOfficinaElettrica =
            codiceServizio == ServizioTypes.ITERO_FV ||
            codiceServizio == ServizioTypes.ITERS_FV;
        return servizioEscludeOfficinaElettrica;
    };

    const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
        (state) => state.iterConnessioniState.nuovoIterConnessioneId
    );

    useEffect(() => {
        if (iterId) {
            dispatch(getIterConnessione({iterConnessioneId: iterId}));
        }
    }, []);

    useEffect(() => {
        nuovoIterConnessioneId && setIterId(nuovoIterConnessioneId);
    }, [nuovoIterConnessioneId]);

    const iterConnessione: IterConnessione | undefined = useSelector<
        RootState,
        IterConnessione | undefined
    >((state) =>
        state.iterConnessioniState.iterConnessioni.results.find(
            (iterConnessione) => iterConnessione?.id == iterId
        )
    );

    useEffect(() => {
        iterConnessione && dispatch(getProgettoFromIter(iterId));

        if (iterConnessione && iterConnessione.produttore) {
            dispatch(getProduttore(iterConnessione.produttore));
        }
    }, [iterConnessione]);

    const cliente: Cliente | undefined = useSelector<
        RootState,
        Cliente | undefined
    >((state) =>
        state.clientiState.clienti.results.find(
            (cliente) => cliente?.id == iterConnessione?.cliente
        )
    );
    const produttore: Soggetto | undefined = useSelector<
        RootState,
        Soggetto | undefined
    >((state) =>
        state.produttoriState.produttori.results.find(
            (produttore) => produttore?.id == iterConnessione?.produttore
        )
    );


    const [currentTab, setCurrentTab] = useState("tab_produttore");
    const handleTabChange = (event: any, newValue: string) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
            if (iterConnessione &&
                ["A", "B", "C"].includes(iterConnessione.stato)) {
                if (!iterConnessione.dati_impostazione_iter_completo?.dati_impostazione_iter_completo) {
                    setCurrentTab("tab_impostazione");
                } else if (!iterConnessione.dati_produttore_completo?.dati_produttore_completo) {
                    setCurrentTab("tab_produttore");
                } else if (!iterConnessione.dati_pod_completo?.dati_pod_completo) {
                    setCurrentTab("tab_pod");
                } else if (["B", "C"].includes(iterConnessione.stato)) {
                    setCurrentTab("tab_avvio");
                } else {
                    setCurrentTab("tab_impostazione");
                }
            }
            iterConnessione &&
            ["D", "E"].includes(iterConnessione.stato) &&
            setCurrentTab("tab_dati_pratica");
            iterConnessione &&
            ["F"].includes(iterConnessione.stato) &&
            setCurrentTab("tab_progetto");
            iterConnessione &&
            ["G", "H", "I", "L"].includes(iterConnessione.stato) &&
            setCurrentTab("tab_richiesta_connessione");
            iterConnessione &&
            ["M", "N"].includes(iterConnessione.stato) &&
            setCurrentTab("tab_raccolta_documenti");
        }
        ,
        [iterConnessione?.stato]
    )

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={iterConnessioniErrorsStack.status == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {iterConnessione && iterConnessione?.tipo_servizio && (
                <ArrowStepper
                    titolo={testoBottoneProseguimento(
                        Object.entries(ServizioTypes).find(
                            ([key, value]) =>
                                key ==
                                tipiServizio.find(
                                    (ts) => ts.id == iterConnessione?.tipo_servizio
                                )?.codice
                        )?.[1]
                    )}
                    passi={passiInizioPratica}
                    colore={
                        ["A", "B"].includes(iterConnessione.stato)
                            ? "#c7b52a"
                            : ["N"].includes(iterConnessione.stato)
                                ? "#658822"
                                : "#03b0e2"
                    }
                    indicePassoCorrente={
                        iterConnessione.stato == "A"
                            ? 0
                            : iterConnessione.stato == "B"
                                ? 1
                                : ["N"].includes(iterConnessione.stato)
                                    ? 3
                                    : 2
                    }
                />
            )}
            {iterConnessione ? (
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <Chip
                                color="primary"
                                variant="outlined"
                                label={iterConnessione?.codice_pratica}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={2}>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                {
                                    tipiServizio.find(
                                        (ts) => ts.id == iterConnessione?.tipo_servizio
                                    )?.codice
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={2}>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                {iterConnessione?.iter_semplificato
                                    ? "iter semplificato"
                                    : "iter ordinario"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5} lg={3}>
                            <Typography variant="h5">{cliente?.ragione_sociale}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <Typography variant="h5">
                                {/* {
							statiIter.results.find(
								(statoIter) => statoIter.stato == iterConnessione?.stato
							)?.stato_desc
						} */}
                                {EsponiStatoSintetico(iterConnessione?.stato)}
                            </Typography>
                            <Typography variant="h5">
                                {utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                        PermissionTypes.PROGETTISTI,
                                    ]) &&
                                    iterConnessione?.stato == "H" &&
                                    ` (${
                                        Object.entries(StatoConnessioneTypes).find(
                                            ([key, value]) =>
                                                key == iterConnessione.stato_richiesta_connessione
                                        )?.[1]
                                    })`}
                            </Typography>
                        </Grid>
                        {utenteAutorizzato(utente, [
                            PermissionTypes.GESTORI_ITER,
                            PermissionTypes.OPERATORI,
                            PermissionTypes.PROGETTISTI,
                        ]) && (
                            <Grid item xs={6} sm={6} md={4} lg={1}>
                                <IterConnessioneAlerChip iterConnessione={iterConnessione}/>
                            </Grid>
                        )}
                    </Grid>

                    <TabContext value={currentTab}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                            }}
                        >
                            <TabList
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: iterConnessione?.dati_impostazione_iter_completo?.dati_impostazione_iter_completo
                                                ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            0.Impostazione
                                        </Typography>
                                    }
                                    value="tab_impostazione"/>

                                <Tab
                                    disabled={!iterConnessione.dati_impostazione_iter_completo?.dati_impostazione_iter_completo}
                                    label={<Typography variant="caption"
                                                       sx={{
                                                           color: !iterConnessione.dati_impostazione_iter_completo ?
                                                               "inherit" : iterConnessione?.dati_produttore_completo?.dati_produttore_completo ?
                                                                   theme.palette.primary.main : theme.palette.error.main,
                                                       }}>
                                        1.Produttore
                                    </Typography>
                                    }
                                    value="tab_produttore"/>
                                <Tab
                                    disabled={!iterConnessione.dati_impostazione_iter_completo?.dati_impostazione_iter_completo || !iterConnessione.produttore}
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: !iterConnessione.produttore
                                                ? "inherit" : iterConnessione?.dati_pod_completo?.dati_pod_completo
                                                    ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            2.dati POD
                                        </Typography>
                                    }
                                    value="tab_pod"
                                />
                                <Tab
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: !iterConnessione?.dati_produttore_completo?.dati_produttore_completo ||
                                            !iterConnessione?.dati_pod_completo?.dati_pod_completo
                                                ? "inherit" : iterConnessione?.dati_avvio_iter_completo?.dati_avvio_iter_completo
                                                    ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            3.Avvio Iter
                                        </Typography>
                                    }
                                    value="tab_avvio"
                                    disabled={
                                        !iterConnessione.dati_impostazione_iter_completo?.dati_impostazione_iter_completo ||
                                        !iterConnessione?.dati_produttore_completo?.dati_produttore_completo ||
                                        !iterConnessione?.dati_pod_completo?.dati_pod_completo
                                    }
                                />
                                <Tab
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: !["D", "E", "F", "G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "")
                                                ? "inherit" : iterConnessione?.assegnabile?.assegnabile && iterConnessione?.progetto_avviabilie?.progetto_avviabilie
                                                    ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            4.dati pratica
                                        </Typography>
                                    }
                                    value="tab_dati_pratica"
                                    disabled={!["D", "E", "F", "G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "")}
                                />
                                <Tab
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: !["F", "G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "")
                                                ? "inherit" : iterConnessione?.progetto_completo?.progetto_completo
                                                    ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            5.PROGETTO
                                        </Typography>
                                    }
                                    value="tab_progetto"
                                    disabled={!["F", "G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "")}
                                />
                                <Tab
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: !iterConnessione?.progetto_completo?.progetto_completo
                                                ? "inherit" : iterConnessione?.autorizzazione_comunale_completa?.autorizzazione_comunale_completa
                                                    ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            5A.AUTORIZZAZIONI COMUNALI
                                        </Typography>
                                    }
                                    value="tab_autorizzazione_comunale"
                                    disabled={
                                        !["G", "H", "I", "L", "M", "N"].includes(
                                            iterConnessione?.stato || ""
                                        )
                                    }
                                />

                                <Tab
                                    label={
                                        <Typography variant="caption" sx={{
                                            color: !iterConnessione?.progetto_completo?.progetto_completo
                                                ? "inherit" : !['G', 'H', 'I', 'L'].includes(iterConnessione?.stato || '')
                                                    ? theme.palette.primary.main : theme.palette.error.main,
                                        }}>
                                            6.RICHIESTA CONNESSIONE
                                        </Typography>
                                    }
                                    value="tab_richiesta_connessione"
                                    disabled={
                                        !["G", "H", "I", "L", "M", "N"].includes(
                                            iterConnessione?.stato || ""
                                        )
                                    }
                                />
                                {produttore?.tipo_persona == "PF" && !servizioEscludeEnea() && (
                                    <Tab
                                        label={
                                            <Typography variant="caption" sx={{
                                                color: !["G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "") || servizioEscludeOfficinaElettrica()
                                                    ? "inherit" : iterConnessione.enea_completo
                                                        ? theme.palette.primary.main : theme.palette.error.main,
                                            }}>
                                                6a.ENEA
                                            </Typography>
                                        }
                                        value="Enea_tab"
                                        disabled={!["G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "") || servizioEscludeEnea()}
                                    />
                                )}
                                {utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                        PermissionTypes.PROGETTISTI,
                                        PermissionTypes.CLIENTI,
                                    ]) &&
                                    produttore?.tipo_persona == "PG" &&
                                    !servizioEscludeOfficinaElettrica() && (
                                        <Tab
                                            label={
                                                <Typography variant="caption" sx={{
                                                    color: !["G", "H", "I", "L", "M", "N"].includes(iterConnessione?.stato || "")
                                                        ? "inherit" : iterConnessione.officina_elettrica_completo
                                                            ? theme.palette.primary.main : theme.palette.error.main,
                                                }}>
                                                    6b.OFFICINA ELETTRICA
                                                </Typography>
                                            }
                                            value="Officina_elettrica_tab"
                                            disabled={
                                                !["G", "H", "I", "L", "M", "N"].includes(
                                                    iterConnessione?.stato || ""
                                                ) || servizioEscludeOfficinaElettrica()
                                            }
                                        />
                                    )}

                                <Tab
                                    label={<Typography variant="caption">7.CONSEGNA</Typography>}
                                    value="tab_raccolta_documenti"
                                    disabled={
                                        !(
                                            (utenteAutorizzato(utente, [
                                                    PermissionTypes.GESTORI_ITER,
                                                    PermissionTypes.OPERATORI,
                                                    PermissionTypes.PROGETTISTI,
                                                ]) &&
                                                ["M", "N"].includes(iterConnessione?.stato || "")) ||
                                            iterConnessione?.stato == "N"
                                        )
                                    }
                                />
                                {utenteAutorizzato(utente, [
                                    PermissionTypes.GESTORI_ITER,
                                    PermissionTypes.OPERATORI,
                                    PermissionTypes.PROGETTISTI,
                                ]) && <Tab label={<Typography variant="caption">8.RACCOLTA DOCUMENTI</Typography>}
                                           value="Raccolta_documenti_tab"/>}
                            </TabList>
                        </Box>

                        <TabPanel value="tab_impostazione">
                            <IterConnessioneIterImpostazioneTab
                                iterConnessione={iterConnessione}
                                produttore={produttore}
                                utente={utente}
                                consentiModifica={
                                    (["A", "B", "C"].includes(iterConnessione?.stato || "") &&
                                        utenteAutorizzato(utente, [
                                            PermissionTypes.GESTORI_ITER,
                                            PermissionTypes.CLIENTI,
                                            PermissionTypes.OPERATORI,
                                            // PermissionTypes.PROGETTISTI,
                                        ])) ||
                                    utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
                                }
                            />
                        </TabPanel>
                        <TabPanel value="tab_produttore">
                            {iterConnessione ? (
                                <IterConnessioneDatiProduttoreTab
                                    iterConnessione={iterConnessione}
                                    consentiModifica={
                                        (["A", "B", "C"].includes(iterConnessione?.stato || "") &&
                                            utenteAutorizzato(utente, [
                                                PermissionTypes.GESTORI_ITER,
                                                PermissionTypes.CLIENTI,
                                                PermissionTypes.OPERATORI,
                                                // PermissionTypes.PROGETTISTI,
                                            ])) ||
                                        utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
                                    }
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="tab_pod">
                            {iterConnessione && produttore ? (
                                <IterConnessioneDatiPodTab
                                    iterConnessione={iterConnessione}
                                    utente={utente}
                                    produttore={produttore}
                                    consentiModifica={
                                        (["A", "B", "C"].includes(iterConnessione?.stato || "") &&
                                            utenteAutorizzato(utente, [
                                                PermissionTypes.GESTORI_ITER,
                                                PermissionTypes.CLIENTI,
                                                PermissionTypes.OPERATORI,
                                                // PermissionTypes.PROGETTISTI,
                                            ])) ||
                                        utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
                                    }
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="tab_avvio">
                            <IterConnessioneAvvioIterTab
                                iterConnessione={iterConnessione}
                                produttore={produttore}
                                utente={utente}
                                consentiModifica={(iterConnessione.dati_impostazione_iter_completo?.dati_impostazione_iter_completo &&
                                        iterConnessione?.dati_produttore_completo?.dati_produttore_completo &&
                                        iterConnessione?.dati_pod_completo?.dati_pod_completo &&
                                        (["A", "B", "C"].includes(iterConnessione?.stato || "") &&
                                            utenteAutorizzato(utente, [
                                                PermissionTypes.GESTORI_ITER,
                                                PermissionTypes.CLIENTI,
                                                PermissionTypes.OPERATORI,
                                                // PermissionTypes.PROGETTISTI,
                                            ]))) ||
                                    utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
                                }
                            />
                        </TabPanel>

                        <TabPanel value="tab_dati_pratica">
                            {iterConnessione && produttore ? (
                                <IterConnessioneDatiPraticaTab
                                    iterConnessione={iterConnessione}
                                    produttore={produttore}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                    ])}
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="tab_progetto">
                            {iterConnessione ? (
                                <IterConnessioneProgettiTab
                                    iterConnessione={iterConnessione}
                                    utente={utente}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.PROGETTISTI,
                                    ])}
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="tab_autorizzazione_comunale">
                            {iterConnessione ? (
                                <IterConnessioneAutorizzazioneComunaleTab
                                    iterConnessione={iterConnessione}
                                    utente={utente}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                    ])}
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="tab_richiesta_connessione">
                            {iterConnessione ? (
                                <IterConnessioneRichiesteConnessioneTab
                                    iterConnessione={iterConnessione}
                                    utente={utente}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                    ])}
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="Enea_tab">
                            {iterConnessione ? (
                                <IterConnessioneEneaTab
                                    iterConnessione={iterConnessione}
                                    utente={utente}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                    ])}
                                />
                            ) : (
                                <CircularProgress color="inherit"/>
                            )}
                        </TabPanel>
                        <TabPanel value="Officina_elettrica_tab">
                            {iterConnessione && produttore?.tipo_persona == "PG" && (
                                <IterConnessioneOfficinaElettricaTab
                                    iterConnessione={iterConnessione}
                                    utente={utente}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                    ])}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value="tab_raccolta_documenti">
                            {((utenteAutorizzato(utente, [
                                            PermissionTypes.GESTORI_ITER,
                                            PermissionTypes.OPERATORI,
                                            PermissionTypes.PROGETTISTI,
                                        ]) &&
                                        ["M", "N"].includes(iterConnessione?.stato || "")) ||
                                    iterConnessione?.stato == "N") &&
                                iterConnessione &&
                                produttore && (
                                    <IterConnessioneConsegnaTab
                                        iterConnessione={iterConnessione}
                                        produttore={produttore}
                                        utente={utente}
                                        consentiModifica={utenteAutorizzato(utente, [
                                            PermissionTypes.GESTORI_ITER,
                                            PermissionTypes.OPERATORI,
                                        ])}
                                    />
                                )}
                        </TabPanel>
                        <TabPanel value="Raccolta_documenti_tab">
                            {iterConnessione?.id && (
                                <IterConnessioneDocumentiTab
                                    iterConnessione_id={iterConnessione.id}
                                    utente={utente}
                                    consentiModifica={utenteAutorizzato(utente, [
                                        PermissionTypes.GESTORI_ITER,
                                        PermissionTypes.OPERATORI,
                                    ])}
                                />
                            )}
                        </TabPanel>
                    </TabContext>
                </Box>
            ) : (
                <Paper sx={{p: 2, backgroundColor: "lightgoldenrodyellow", mb: 2}}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        <ReportGmailerrorredIcon/>
                        Errore caricamento iter connessione
                    </Typography>
                </Paper>
            )}
        </Box>
    );
};

export default IterConnessionePage;
