import React, {useEffect} from "react";
import {useAppDispatch} from "../../../redux/store";
import {useForm, SubmitHandler} from "react-hook-form";
import {Gestore, saveGestore, deleteGestore} from "./gestoriSlice";

import {
    Box,
    TextField,
    Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";

interface GestoreFormProps {
    gestore: Gestore;
    consentiModifica: boolean;
}

const GestoreForm = ({gestore, consentiModifica}: GestoreFormProps) => {
    const {handleSubmit, register, formState: {errors, isDirty, isValid}, watch, getValues, reset} = useForm<Gestore>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        reset(gestore);
    }, [gestore, reset]);

    const handlerSaveGestore = (gestore: Gestore) => {
        consentiModifica && dispatch(saveGestore(gestore));
    };

    const onSubmit: SubmitHandler<Gestore> = (gestore) => {
        handlerSaveGestore(gestore);
    };

    const handlerDetailChangeSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveGestore(formValues);
        }
    };

    // gestione chiusura modal su scelta "Annulla"
    const [openConferma, setOpenConferma] = React.useState(false);
    const handleConfermaClose = () => setOpenConferma(false);
    const handleModalopenConfermaOpen = () => {
        setOpenConferma(true);
    };

    // gestione cancellazione gestore
	const handlerDeleteGestore = (gestoreToDelete: Gestore) => {
		if (gestoreToDelete != undefined) {
			dispatch(deleteGestore(gestoreToDelete));
			handleConfermaClose();
		}
	};

    return (
        <Box sx={{py : 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {gestore?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {gestore.errorsStack.fieldsErrors.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid size={{xs:12, sm:6}}>
                        <TextField
                            // label="Ragione Sociale"
                            variant="outlined"
                            size="small"
                            {...register("ragione_sociale", {required: "Campo obbligatorio"})}
                            onBlur={handlerDetailChangeSubmit}
                            error={!!errors?.ragione_sociale}
                            helperText={errors?.ragione_sociale?.message}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    <Grid size={{xs:12, sm:6}}>
                        <TextField
                            // label="Codice Distributore"
                            variant="outlined"
                            size="small"
                            {...register("codice_distributore", {required: "Campo obbligatorio"})}
                            onBlur={handlerDetailChangeSubmit}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    {/* --- cancellazione commentata perchè è necessaria una progettazione attenta del foreign key ---*/}
                    {/*<Grid size={{xs:12, sm:1}}>*/}
                    {/*    <IconButton*/}
                    {/*        // label="Codice Distributore"*/}
                    {/*        onClick={() => handleModalopenConfermaOpen()}*/}
                    {/*    >*/}
                    {/*        <DeleteForeverIcon fontSize="inherit"/>*/}
                    {/*    </IconButton>*/}
                    {/*</Grid>*/}
                </Grid>
            </form>
            <ConfermaAnnulla
                handleAnnulla={handleConfermaClose}
                handleConferma={() => handlerDeleteGestore(gestore)}
                domanda={
                    "Sicuro di voler cancellare il gestore " + gestore.ragione_sociale

                }
                open={openConferma}
            />
        </Box>
    );
};

export default GestoreForm;
