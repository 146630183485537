import React, {useEffect} from "react";
import {useAppDispatch} from "../../../redux/store";
import {useForm, SubmitHandler} from "react-hook-form";
import {RegimeCommerciale, saveRegimeCommerciale, deleteRegimeCommerciale} from "./regimiCommercialiSlice";

import {
    Box,
    TextField,
    Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";

interface RegimeCommercialeFormProps {
    regimeCommerciale: RegimeCommerciale;
    consentiModifica: boolean;
}

const RegimeCommercialeForm = ({regimeCommerciale, consentiModifica}: RegimeCommercialeFormProps) => {
    const {handleSubmit, register, formState: {errors, isDirty, isValid}, watch, getValues, reset} = useForm<RegimeCommerciale>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        reset(regimeCommerciale);
    }, [regimeCommerciale, reset]);

    const handlerSaveRegime = (regime: RegimeCommerciale) => {
        consentiModifica && dispatch(saveRegimeCommerciale(regime));
    };

    const onSubmit: SubmitHandler<RegimeCommerciale> = (regime) => {
        handlerSaveRegime(regime);
    };

    const handlerDetailChangeSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveRegime(formValues);
        }
    };

    // gestione chiusura modal su scelta "Annulla"
    const [openConferma, setOpenConferma] = React.useState(false);
    const handleConfermaClose = () => setOpenConferma(false);
    const handleModalopenConfermaOpen = () => {
        setOpenConferma(true);
    };

    // gestione cancellazione regime commerciale
    const handlerDeleteRegime = (regimeToDelete: RegimeCommerciale) => {
        if (regimeToDelete != undefined) {
            dispatch(deleteRegimeCommerciale(regimeToDelete));
            handleConfermaClose();
        }
    };

   return (
        <Box sx={{py: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {regimeCommerciale?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {regimeCommerciale.errorsStack.fieldsErrors.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid size={{xs:12, sm:6}}>
                        <TextField
                            // label="Nome"
                            variant="outlined"
                            size="small"
                            {...register("nome", {required: "Campo obbligatorio"})}
                            onBlur={handlerDetailChangeSubmit}
                            error={!!errors?.nome}
                            helperText={errors?.nome?.message}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    <Grid size={{xs:12, sm:6}}>
                        <TextField
                            // label="Descrizione"
                            variant="outlined"
                            size="small"
                            {...register("descrizione")}
                            onBlur={handlerDetailChangeSubmit}
                            fullWidth
                            disabled={!consentiModifica}
                        />
                    </Grid>
                    {/* --- cancellazione commentata perchè è necessaria una progettazione attenta del foreign key ---*/}
                    {/*<Grid size={{xs:12, sm:1}}>*/}
                    {/*    <IconButton*/}
                    {/*        // label="Codice Distributore"*/}
                    {/*        onClick={() => handleModalopenConfermaOpen()}*/}
                    {/*    >*/}
                    {/*        <DeleteForeverIcon fontSize="inherit"/>*/}
                    {/*    </IconButton>*/}
                    {/*</Grid>*/}
                </Grid>
            </form>
            <ConfermaAnnulla
                handleAnnulla={handleConfermaClose}
                handleConferma={() => handlerDeleteRegime(regimeCommerciale)}
                domanda={
                    "Sicuro di voler cancellare il regime commerciale " + regimeCommerciale.nome

                }
                open={openConferma}
            />
        </Box>
    );
};

export default RegimeCommercialeForm;
