import React, {useState} from "react";
import {Utente} from "../../authentication/authenticationSlice";

import {PermissionTypes} from "../../authentication/permissionsGroups";
import {styled, useTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {utenteAutorizzato} from "../../authentication/UtenteAutorizzato";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import {Button} from "@mui/material";
import {Box} from "@mui/system";

const styleListItem = {justifyContent: "center"};

interface ElementoMenuTendinaProps {
    utente: Utente;
    path: string;
    label: string;
    gruppiAutorizzati: PermissionTypes[];
    open: boolean;
    selected: boolean;
    Icon: React.ElementType;
    tooggleDrawer: () => void;
    SottoMenu?: JSX.Element;
}


const ElementoMenuTendina = ({
                                     utente,
                                     path,
                                     label,
                                     open,
                                     gruppiAutorizzati,
                                     selected,
                                     Icon,
                                     tooggleDrawer,
                                     SottoMenu,
                                 }: ElementoMenuTendinaProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    // const matches = useMediaQuery("min-width:900px)");
    const mobiletooggleDrawer = () => {
        matches && tooggleDrawer();
    };

    const [sottoMenuAperto, setSottoMenuAperto] = useState(false);

    const handleClick = () => {
        setSottoMenuAperto(!sottoMenuAperto);
    };

    return (
        <>
            {utenteAutorizzato(utente, gruppiAutorizzati) && (
                <>
                    <ListItem
                        component={Button}
                        onClick={!!SottoMenu || !open ? handleClick : () => {
                        }}
                        key={path}
                        sx={styleListItem}
                    >
                        <ListItemIcon style={{minWidth: "auto"}}>
                            <Tooltip title={label}>
                                {/* <Badge badgeContent={8} color="error"> */}
                                <Icon
                                    sx={{color: selected ? "primary.main" : "inherit"}}
                                />
                            </Tooltip>
                        </ListItemIcon>

                        {open && (
                            <ListItemText
                                style={{marginLeft: 12}}
                                primary={label}
                                sx={{color: selected ? "primary.main" : "#222"}}
                                // className={selected ? classes.iconSelected : ""}
                                onClick={mobiletooggleDrawer}
                            />
                        )}
                        {sottoMenuAperto ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    {
                        <Collapse in={sottoMenuAperto} timeout="auto" unmountOnExit sx={{ml: 2}}>
                            {SottoMenu}
                        </Collapse>
                    }
                </>
            )}
        </>
    );
};

export default ElementoMenuTendina;
