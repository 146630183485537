import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";


import {
	Installatori,
	Installatore,
	fetchInstallatori,
	resetInstallatoreCorrente,
} from "./installatoriSlice";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaClienti,
} from "../../userPreference/userPreferenceSlice";

import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

const InstallatoriPage = () => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// type LocationState = {
	// 	statoIterId?: string;
	// 	clienteIterId?: number;
	// 	iterSemplificato?: string;
	// };
	const location = useLocation();

	// const preferenzeListaInstallatori = useSelector<
	// 	RootState,
	// 	PreferenzeLista | undefined
	// >((state) => state.userPreferenceState.preferenzeListaClienti);

	const installatori: Installatori = useSelector<RootState, Installatori>(
		(state) => state.installatoriState.installatori
	);

	const [page, setPage] = useState<number>(1);
	const [numeroRecord, setNumeroRecord] = useState<number>(10);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [ordinamento, setOrdinamento] = useState<OrdinamentoLista | undefined>(
		undefined
	);
	useEffect(() => {
		dispatch(
			fetchInstallatori({
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		// dispatch(
		// 	setPreferenzeListaClienti({
		// 		numeroRecord: numeroRecord,
		// 		page: page,
		// 		ordinamento: ordinamento,
		// 	})
		// );
	}, [page, numeroRecord, search]);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const handlerCreateInstallatore = () => {
		dispatch(resetInstallatoreCorrente());

		navigate(Routes_path.INSTALLATORE);
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			{/* <List sx={{ mt: 2 }}> */}
			<List>
				<ListItem>
					<Button
						color="primary"
						startIcon={<AddIcon />}
						onClick={(event) => {
							event.preventDefault();
							handlerCreateInstallatore();
						}}
					>
						Aggiungi nuovo Installatore
					</Button>
				</ListItem>
				<ListItemText
					key={"Lista_campi_clienti"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid size={{xs:12, sm:12, md:8, lg:6}}>
							<Typography variant="h5" gutterBottom>
								Ragione Sociale
							</Typography>
						</Grid>
						<Grid size={{xs:10, sm:10, md:11, lg:6}}>
							<Typography variant="h5" gutterBottom>
								Cellulare
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				{installatori.results?.map((installatore, index) => (
					<ListItemButton
						key={index}
						component={Link}
						to={Routes_path.INSTALLATORE + installatore.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ width: "100%", mb: 1 }}>
							<Grid size={{xs:12, sm:12, md:8, lg:6}}>
								{installatore.ragione_sociale}
							</Grid>
							<Grid size={{xs:12, sm:12, md:8, lg:6}}>
								{installatore.cellulare}
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={installatori.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						<Typography noWrap>
							Totale clienti:{"  "}
							{installatori.count}
						</Typography>

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>

						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default InstallatoriPage;
