import { handleResponse, handleError, headerset } from "../apiUtils";
import { Gestore } from "../../components/anagrafiche/gestori/gestoriSlice";

const baseUrl = (gestore_id?: number | null) => {
	if (gestore_id) {
		return process.env.API_URL + "/api/anagrafiche/gestori/" + gestore_id + "/";
	} else {
		return process.env.API_URL + "/api/anagrafiche/gestori/";
	}
};

export function fetchGestori(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();

	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getGestore(gestoreId: number) {
	let url = baseUrl(gestoreId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveGestore(gestoreToSave: Gestore) {
	const gestore = {
		...gestoreToSave,
	};

	return fetch(baseUrl(gestoreToSave.id), {
		method: gestoreToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...gestore,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteGestore(gestoreToDelete: Gestore) {
	return fetch(baseUrl(gestoreToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
