import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as tipiPraticaApi from "../../../api/anagrafiche/tipiPraticaApi";
import { toast } from "react-toastify";

export interface TipoPratica {
	id?: number;
	nome?: string;
	descrizione?: string;
	compatibile_iter_semplificato?: boolean;
	errorsStack?: ErrorsStack;
}

export interface TipiPratica {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoPratica[];
	errorsStack: ErrorsStack;
}

export interface TipiPraticaStrutturaState {
	tipiPratica: TipiPratica;
}

const initialState: TipiPraticaStrutturaState = {
	tipiPratica: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiPratica = createAsyncThunk(
	"tipiPratica/fetchTipiPratica",
	async (parametri: {
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await tipiPraticaApi.fetchTipiPratica(
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getTipoPratica = createAsyncThunk(
	"tipiPratica/getTipoPratica",
	async (tipoPraticaId: number) => {
		return await tipiPraticaApi.getTipoPratica(tipoPraticaId);
	}
);

export const saveTipoPratica = createAsyncThunk(
	"tipiPratica/saveTipoPratica",
	async (tipoPraticaToSave: TipoPratica, thunkApi) => {
		return await tipiPraticaApi
			.saveTipoPratica(tipoPraticaToSave)
			.then((response) => {
				thunkApi.dispatch(fetchTipiPratica({}));
				return response;
			});
	}
);

export const deleteTipoPratica = createAsyncThunk(
	"tipiPratica/deleteTipoPratica",
	async (tipoPraticaToDelete: TipoPratica, thunkApi) => {
		return await tipiPraticaApi.deleteTipoPratica(tipoPraticaToDelete).then((response) => {thunkApi.dispatch(fetchTipiPratica({})); return response;});
	}
);

export const tipiPraticaSlice = createSlice({
	name: "tipiPratica",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Tipi Pratica
		builder.addCase(fetchTipiPratica.pending, (state, action) => {
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchTipiPratica.fulfilled, (state, action) => {
			state.tipiPratica = action.payload;

			state.tipiPratica.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiPratica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Tipo Pratica
		builder.addCase(getTipoPratica.pending, (state, action) => {
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getTipoPratica.fulfilled, (state, action) => {
			state.tipiPratica.results = state.tipiPratica.results.map(
				(tipoPratica) => {
					if (tipoPratica.id == action.payload.id) {
						return action.payload;
					} else {
						return tipoPratica;
					}
				}
			);
			state.tipiPratica.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getTipoPratica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Tipo Pratica
		builder.addCase(saveTipoPratica.pending, (state, action) => {
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveTipoPratica.fulfilled, (state, action) => {
			state.tipiPratica.results = state.tipiPratica.results.map(
				(tipoPratica) => {
					if (tipoPratica.id == action.payload.id) {
						return {
							...action.payload,
							errorsStack: {
								status: ErrorStatusTypes.OK,
							},
						};
					} else {
						return tipoPratica;
					}
				}
			);
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Tipo Pratica salvato.");
		});
		builder.addCase(saveTipoPratica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.tipiPratica.results = state.tipiPratica.results.map(
				(tipoPratica) => {
					if (tipoPratica.id == action.meta.arg.id) {
						return {
							...tipoPratica,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return tipoPratica;
					}
				}
			);
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// cancella Tipo Pratica
		builder.addCase(deleteTipoPratica.pending, (state, action) => {
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteTipoPratica.fulfilled, (state, action) => {
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.tipiPratica.results = state.tipiPratica.results.filter(
				(tipoPratica) => tipoPratica.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Tipo Pratica cancellato.");
		});
		builder.addCase(deleteTipoPratica.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiPratica.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = tipiPraticaSlice.actions;

export const tipiPraticaSliceReducer = tipiPraticaSlice.reducer;
