import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../redux/store";
import {
    fetchRegimiCommerciali,
    saveRegimeCommerciale,
    RegimeCommerciale,
    RegimiCommerciali
} from "./regimiCommercialiSlice";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../common/errorsDeclarations";
import RegimeCommercialeForm from "./RegimeCommercialeForm";
import RegimeCommercialeNuovoForm from "./RegimeCommercialeNuovoForm";

import {
    Box,
    Button,
    List,
    ListItem,
    Pagination,
    Typography,
    ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse";
import GestoreNuovoForm from "../gestori/GestoreNuovoForm";


const RegimiCommercialiPage = () => {
    const dispatch = useAppDispatch();

    // Caricamento dallo store
    const regimi = useSelector<RootState, RegimiCommerciali >(
        (state) => state.regimiCommercialiState.regimiCommerciali
    );

    const [page, setPage] = useState<number>(1);
    const [numeroRecord, setNumeroRecord] = useState<number>(10);
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchRegimiCommerciali({
            numeroRecord: numeroRecord,
            page: page,
            // search: search,
        }));
        setRefresh(false);
    }, [page, numeroRecord, refresh]);

// gestione chiusura form nuovo elemento su azione "Annulla"
    const [formAttivo, setFormAttivo] = React.useState(false);
    const handleOpenFormToAdd = () => setFormAttivo(true);
    const handleCloseForm = () => setFormAttivo(false);

    const handleRefresh = () => setRefresh(true);

// definizione elemento nuovo vuoto con aggancio errorsStack
    const regimeNuovoFieldsErrors: ErrorsStack = useSelector<
        RootState,
        ErrorsStack
    >((state) => state.regimiCommercialiState.regimiCommerciali.errorsStack);

    const [regimeNuovo, setRegimeNuovo] = React.useState<RegimeCommerciale>({
        id: undefined,
        nome: "",
        descrizione: "",
        errorsStack: regimeNuovoFieldsErrors,
    });

    useEffect(() => {
        setRegimeNuovo({
            ...regimeNuovo,
            errorsStack: regimeNuovoFieldsErrors,
        });
    }, [regimeNuovoFieldsErrors]);

 	// salvataggio nuovo elemento con hook di chiusura form se esito "success"
    const handlerSaveRegime = (regimeToSave: RegimeCommerciale) => {
        dispatch(saveRegimeCommerciale(regimeToSave));
    };

    useEffect(() => {
        if (regimi.errorsStack.status == ErrorStatusTypes.SUCCESS)
            setFormAttivo(false);
    }, [regimi.errorsStack.status]);


    return (
        <Box sx={{
            backgroundColor: "#fff",
            elevation: 20,
            // p: 2,
            "& .campo-editabile": {
                backgroundColor: "#bbbbbb11",
                background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
                // borderWidth: "5px",
                borderColor: "#ff91003d",
                // borderColor: "#0000003d",
                // borderStyle: "outset",
                borderWidth: 3,
                borderStyle: "ridge",
                color: "#1a3e72",
                fontWeight: "600",
            },
            "& .campo-errore": {
                backgroundColor: "rgba(124, 3, 0, 0.3)",
                color: "#ffffff",
                fontWeight: "600",
            },
        }}>

            <Collapse in={formAttivo}>
                <Box sx={{padding: 2}}>
                    {formAttivo && (
                        <RegimeCommercialeNuovoForm
                            regimeCommercialeNuovo={regimeNuovo}
                            annulla={handleCloseForm}
                            saveRegimeCommerciale={handlerSaveRegime}
                        />
                    )}
                </Box>
            </Collapse>

            <List>
                <ListItem>
                    <Button
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={handleOpenFormToAdd}
                    >
                        Aggiungi nuovo Regime Commerciale
                    </Button>
                </ListItem>
                <ListItemText
                    sx={{
                        backgroundColor: "#1b75b1",
                        color: "#ddd",
                        py: 1,
                        mb: 1,
                    }}
                >
                    <Grid container spacing={2} sx={{px: 2}}>
                        <Grid size={{xs: 6}}>
                            <Typography variant="h5" gutterBottom>Nome</Typography>
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <Typography variant="h5" gutterBottom>Descrizione</Typography>
                        </Grid>
                    </Grid>

                </ListItemText>
                {regimi.results.map((regime, index) => (
                    <Box
                        key={regime.id}
                        sx={{
                            backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                            // padding: 2,
                            px: 2,
                            // pb: 2
                        }}
                    >
                        <RegimeCommercialeForm
                            key={regime.id}
                            regimeCommerciale={regime}
                            consentiModifica={true}
                        />
                    </Box>
                ))}

                <ListItem sx={{justifyContent: "space-between", mt: 2}}>
                    <Pagination
                        count={regimi.num_pages}
                        page={page}
                        onChange={(_, value) => setPage(value)}
                    />
                    <Typography>Totale: {regimi.count}</Typography>
                    <FormControl sx={{width: "100px"}}>
                        <InputLabel>Record</InputLabel>
                        <Select
                            value={numeroRecord.toString()}
                            onChange={(e) => setNumeroRecord(Number(e.target.value))}
                            label="Record"
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </Box>
    );
};

export default RegimiCommercialiPage;
